<template>
    <div class="container">
        <div class="p-4">
            <form @submit.prevent="selectedAddress">
                <div class="d-flex justify-content-between">
                    <h2>Select Address</h2>
                        <b-button variant="primary" title="proceed" type="submit"
                                  class="btn btn-sm">
                            Proceed
                        </b-button>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4"  v-for="(address, index) in addresses"  v-bind:key="index" @click="setActive(index)"
                        >
                        <label>
                            <div class="card card-pricing border-0 text-center mb-4 card-input-address"  :class="[(activeIndex == index) ? 'active':'']">
                                <div class="card-header">
                                <div class="float-left radio-container">
                                    <input type="radio" name="address" class="card-input-element" :value="address"
                                           v-model="radio"/>
                                           <span class="checkmark"></span>
                                </div>
                                <div class="float-right">
                                    <b-button variant="primary" class="btn btn-sm btn-icon-only rounded-circle"
                                              @click="editItem(address)">
                                        <i class="simple-icon-pencil"></i>
                                    </b-button>
                                </div>
                                </div>
                                <div class="card-body ">
                                    <strong><span class="text-muted">{{ address.name }}</span></strong><br>
                                    <span class="text-muted">{{ address.address_line_1 }}</span><br>
                                    <span class="text-muted">{{ address.address_line_2 }}</span><br>
                                    <span class="text-muted">{{ address.locality }} </span><br>
                                    <span class="text-muted">{{ address.district }}, {{ address.state }}</span><br>
                                    <span class="text-muted">{{ address.country }}</span><br>
                                    <span class="text-muted">{{ address.pin_code }}</span>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </form>
            <button id="fixedButton"
                    type="button"
                    class="btn base-button btn-icon-only rounded-circle btn-default btn-xl"
                    @click="openModal">
                <span class="btn-inner--icon"><i class="ni ni-fat-add"></i></span>
                <span class="fixed-text">New Address</span>
            </button>
            <b-modal title="Address" id="modal" size="lg" no-close-on-esc hide-footer @hidden="model={}">
                <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                    <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                        <div class="row">
                            <div class="col-md-6">
                                <validated-input name="House Name / Flat No" label="House Name / Flat No"
                                                 v-model="model.name" :rules="rules.name"/>
                            </div>
                            <div class="col-md-6">
                                <validated-input name="Address line 1" label="Address line 1"
                                                 v-model="model.address_line_1"
                                                 :rules="rules.address_line_1"/>
                            </div>
                            <div class="col-md-6">
                                <validated-input name="Address line 2" label="Address line 2"
                                                 v-model="model.address_line_2"
                                                 :rules="rules.address_line_2"/>
                            </div>
                            <div class="col-md-6">
                                <validated-input name="Locality" label="Locality" v-model="model.locality"
                                                 :rules="rules.locality"/>
                            </div>
                            <div class="col-md-6">
                                <validated-input name="District" label="District" v-model="model.district"
                                                 :rules="rules.district"/>
                            </div>
                            <div class="col-md-6">
                                <validated-input name="State" label="State" v-model="model.state"
                                                 :rules="rules.state"/>
                            </div>
                            <div class="col-md-6">
                                <validated-input name="Country" label="Country" v-model="model.country"
                                                 :rules="rules.country"/>
                            </div>
                            <div class="col-md-6">
                                <validated-input name="Pin code" label="Pin code" v-model="model.pin_code"
                                                 :rules="rules.pin_code" @keypress="isNumber"/>
                            </div>
                            <div class="col-md-6">
                                <b-button type="submit" variant="success">
                                    Save
                                </b-button>
                            </div>

                        </div>
                    </b-form>
                </validation-observer>
            </b-modal>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@data/urls';

export default {
    name: 'AddressList',
    data () {
        return {
            addresses: [],
            radio: null,
            activeIndex: 'undefined',
            model: {
                user_id: this.$route.params.id,
                name: '',
                username: '',
                address_line_1: '',
                address_line_2: '',
                locality: '',
                district: '',
                state: '',
                country: '',
                pin_code: ''
            },
            rules: {
                name: {
                    required: true
                },
                address_line_1: {
                    required: true
                },
                district: {
                    required: true
                },
                state: {
                    required: true
                },
                country: {
                    required: true
                },
                pin_code: {
                    required: true
                }
            }
        };
    },
    beforeMount () {
        this.loadData();
    },
    methods: {
        openModal () {
            this.$bvModal.show('modal');
        },
        setActive (index) {
            this.activeIndex = index;
        },
        setData (response) {
            console.log('this.res', response);
            if (response.data.User) {
                this.addresses = response.data.Addresses;
                console.log('this.address', this.addresses);
                this.user = response.data.User;
                console.log('this.user', this.user);
            }
        },

        async loadData () {
            const response = await axios.post(urls.userAddress.details + '?id=' + this.$route.params.id);
            console.log('response:>', response.data);
            this.setData(response);
        },

        async selectedAddress () {
            console.log('data =>', this.radio);
            const sentData = {
                user_id: this.$route.params.id,
                user_address_id: this.radio.id
            };
            const response = await axios.post(urls.shopOrder.placeorder, sentData);
            console.log('response:>>>', response.data);
            if (response.data.Error === false) {
                this.$router.push({ path: '/success/' });
            } else {
                alert('Something went wrong..!');
            }
        },
        async onSubmit () {
            if (this.model.id) {
                const url = urls.userAddress.update;
                const response = await axios.form(url, this.model);
                console.log('response', response);

                const arr = this.addresses;
                const index = arr.map(function (o) {
                    return o.id;
                }).indexOf(this.model.id);

                arr.splice(index, 1);
                arr.splice(index, 0, response.data.obj[0]);
                console.log('response-inx', index, 'array', arr);

                this.$bvModal.hide('modal');
            } else {
                const url = urls.userAddress.create;
                const response = await axios.form(url, this.model);
                console.log('add response', response);
                this.addresses.push(response.data.obj);
                this.$bvModal.hide('modal');
            }
        },
        async editItem (item) {
            this.model = {
                id: item.id,
                user_id: this.$route.params.id,
                name: item.name,
                address_line_1: item.address_line_1,
                address_line_2: item.address_line_2,
                locality: item.locality,
                district: item.district,
                state: item.state,
                country: item.country,
                pin_code: item.pin_code
            };
            this.$bvModal.show('modal');
        },
        isNumber: function (evt) {
            evt = (evt) || window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        }

    }
};
</script>

<style scoped>
label {
    width: 100%;
}

.card-input-address {
    margin: 10px;
    padding: 00px;
}

.card-input-address:hover {
    cursor: pointer;
}

.card-input-address {
    box-shadow: 0 0 1px 1px #f95700ff;
    /*background-color: white;*/
    /*color: #fbb72c;*/
}

#fixedButton {
    position: fixed;
    bottom: 5%;
    right: 2%;
    width: 50px;
    height: 50px;
    font-size: 33px;
    background: #2c7701;
}

#fixedButton .btn-inner--icon i:not(.fas):not(.fab) {
    position: relative;
    top: 4px;
}

#fixedButton .fixed-text {
    display: none;
    position: absolute;
    right: 35px;
    top: 12px;
    left: -120px;
    background: #2c7701;
    border-radius: 10px;
    padding: 2px 6px;
    font-size: 13px;
    transform: scaleX(0);
    transition: transform 0.5s ease-in-out;
}

#fixedButton:hover .fixed-text {
    display: block;
    transform: scaleX(1);
    transform-origin: 100% 50%;

}

#fixedButton:focus,
#fixedButton:focus .fixed-text {
    box-shadow: none;
    background: #2c7701;

}

.card-input-address.active {

    box-shadow: 0 0 1px 1px rgb(0, 255, 34);
}
.card-header{

    border-bottom: none;
}
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #cfcfcf;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #cfcfcf;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #00ff4c;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
left: 5px;
border-radius: 50%;
content:'\2713';
color: white;
font-size: 18px;
}
</style>
